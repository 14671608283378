<script lang="ts">
    import type { PostBoxData } from '../interfaces/types';
    import PostBox from '@/elements/Feed/PostBox.svelte';
    import Widgets from '@/elements/profile/Widgets.svelte';

    interface CustomWindow extends Window {
        Post: any;
        PostsPaginator: any;
        app: any;
        lists: any;
    }

    const customWindow = window as unknown as CustomWindow;
    const Post = customWindow.Post;
    const PostsPaginator = customWindow.PostsPaginator;
    const app = customWindow.app;
    const lists = customWindow.lists;

    export let data: PostBoxData;
    export let reportStatuses: [];

    const pageTitle = `${data.user.name}'s Post`;

    function handlePostAction(event: CustomEvent<{ action: string; postId: number }>) {

        const { action, postId } = event.detail;

        switch (action) {
            case 'like':
                Post.reactTo('post', postId);
                break;
            case 'comment':
                Post.showPostComments(postId, 6);
                break;
            case 'tip':
                // TODO: Implement tip functionality
                break;
        }
    }
</script>

<svelte:head>
    <title>{pageTitle}</title>
</svelte:head>

<section>
    <div class="row">
        <div class="min-vh-100 col-12 col-md-8 border-right pr-md-0">
            <div class="feed-box posts-wrapper mb-3 mt-0 pt-4">
                <PostBox
                    {data}
                    {Post}
                    {PostsPaginator}
                    {app}
                    {lists}
                    {reportStatuses}
                    on:postAction="{handlePostAction}"
                />
            </div>
        </div>
        <div class="col-12 col-md-4 d-none d-md-block pt-3">
            <Widgets {data} />
        </div>
    </div>
</section>
